import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

async function fetchToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    return null;
  }
  const response = await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account
    })
    .catch(async e => {
      //AD is angry we aren't connecting from the same location, reauthenticate.
      if (e instanceof InteractionRequiredAuthError) {
        // try reauthenticating using a popup to prevent data loss
        return await msalInstance.acquireTokenPopup(loginRequest).catch(e => {
          // if this fails, use redirect to re authenticate
          msalInstance.acquireTokenRedirect(loginRequest).catch(e => {
            console.log(e);
          });
        });
      } else {
        console.log(e);
      }
      throw e;
    });
  return response.accessToken;
}

export default fetchToken;
